import React from "react";

import AppLayout from "../../../components/shared/AppLayout";

const PageTermsAndConditions03 = () => {
    return (
        <AppLayout hasHeader={true} isBackBtn={true} hasFooter={true}>
            <div className="app-page page-terms-and-conditions">

                <div className="terms-and-conditions">

                    <div className="container">

                        <p className="terms-and-conditions__title">
                            <strong>網路投保服務契約書</strong><br/>
                            <span>網路保險服務契約書</span>
                        </p>

                        <div className="terms-and-conditions__wrapper">
                            <p>第一條 契約之適用範圍<br/>當事人間依電子簽章法及相關法令之規定從事保險電子交易者，適用本契約之約定。但個別網路 保險服務契約對消費者之保護更有利者，從其約定。</p>
                            <p>第二條 名詞定義<br/>本契約之名詞定義如下：<br/>一、「保險電子交易」：指消費者經由網際網路與本公司資訊系統電腦連線，且利用電子簽章或 其他足資辨識消費者身分之方式，直接取得本公司所提供之各項保險服務。<br/>二、「電子訊息」：指本公司或消費者經由網際網路連線傳遞之訊息。<br/>三、「數位簽章」：指將電子文件以數學演算法或其他方式運算為一定長度之數位資料，以簽署 人之私密金鑰對其加密，形成電子簽章，並得以公開金鑰加以驗證者。<br/>四、「私密金鑰」：指一組具有配對關係之數位資料中，由簽章製作者保有之數位資料，該數位 資料係作電子訊息解密及製作數位簽章之用。<br/>五、「公開金鑰」：指一組具有配對關係之數位資料中，用以對電子訊息加密、或驗證簽署者身 分及數位簽章真偽之數位資料。<br/>六、「加密」：指利用數學演算法或其他方法，將電子文件以亂碼方式處理。<br/>七、「電子簽章」：指依附於電子文件並與其相關連，用以辨識及確認電子文件簽署人身分、資 格及電子文件真偽者。<br/>八、「憑證」：指載有簽章驗證資料，用以確認簽署人身分、資格之電子形式證明。<br/>九、「資訊系統」：指產生、送出、收受、儲存或其他處理電子形式訊息資料之系統。</p>
                            <p>第三條 連線所使用之網路<br/>本公司及消費者應各自與網路業者簽訂網路服務契約，並各自負擔網路使用之費用。</p>
                            <p>第四條 網頁之確認<br/>消費者與本公司交易前，應先確認本公司正確之網址。本公司應盡善良管理人之注意義務，隨時 維護網站的正確性與安全性，以避免消費者之權益受損。</p>
                            <p>第五條 電子訊息之接收與回應<br/>本公司接收含數位簽章或經本公司及消費者同意用以辨識身分之電子訊息後，應立即以下列方式 之一要求消費者再確認：<br/>一、以資訊系統自動回覆通知消費者。<br/>二、以資訊系統再次確認裝置提示消費者。經消費者依前項規定再確認者，該項電子訊息視為已 經本公司受理。本公司受理消費者之電子訊息後，應即時進行檢核或處理，並於三日內將結果通 知消費者。要求保險公司同意承保後，將網路保險交易成功訊息（內容包含保險單號碼或交易序 號、保險單生效時間、保險金額等重要資訊）傳送予消費者。要求保險公司同意承保後，保險契</p>
                            <p><b>網路投保服務契約書</b><br/>約即為成立。本公司或消費者接收來自對方任何電子訊息，若無法辨識其身分或內容時，視為傳 送作業未完成。但本公司可確定消費者身分時，應立即將內容無法辨識之事實通知消費者。</p>
                            <p>第六條 電子訊息之不處理<br/>有下列情形之一者，本公司得不處理任何接收之電子訊息：<br/>一、本公司能舉出證據有具體理由懷疑電子訊息之真實性或所指定事項之正確性者。<br/>二、本公司依據電子訊息處理，將違反相關法令或保險契約之規定者。<br/>本公司不處理前項電子訊息者，應同時將不處理之具體理由及情形通知消費者。</p>
                            <p>第七條 消費者軟硬體安裝與風險<br/>消費者申請使用本契約之服務項目，應自費安裝其所需之電腦軟體、硬體，以及其他與安全相關 之設備。消費者於使用本公司所交付之軟硬體設備時，如因可歸責於本公司之事由致受有損害， 得向本公司請求賠償。</p>
                            <p>第八條 消費者之注意義務<br/>消費者對使用者帳號、密碼、憑證及相關文件，應妥善保管。消費者輸入前項密碼連續錯誤達5 次時，本公司資訊系統即自動停止消費者使用本契約之服務。消費者如擬恢復使用，應向本公司 提出申請。</p>
                            <p>第九條 交易核對<br/>本公司於每筆交易指示處理完畢後，以電子訊息或雙方約定之方式通知消費者，消費者應核對其 結果有無錯誤。如有不符，應於通知到達之日起○○日內（不得少於四十五日），通知本公司查 明。本公司對於消費者之通知，應即進行調查，並於通知到達本公司之日起45日內，將調查之情 形或結果覆知消費者。</p>
                            <p>第十條 電子訊息錯誤之處理<br/>消費者利用本契約之服務，如其電子訊息因不可歸責於消費者之事由而發生錯誤者，本公司應協 助消費者更正，並提供其他必要之協助。前項服務因可歸責於本公司之事由而發生錯誤者，本公 司應於知悉時，立即更正，並同時以電子訊息或雙方約定之方式通知消費者。</p>
                            <p>第十一條 電子文件之合法授權與責任<br/>雙方應確保所傳送至對方之電子訊息均經合法授權。雙方於發現有第三人冒用或盜用使用者帳號、 密碼、憑證或其他任何未經合法授權之情形，應立即以電話或書面或其他約定方式通知他方停止 使用該服務並採取防範之措施。本公司接受通知前，已依前項服務之指示為給付者，得對抗消費 者。但本公司有故意或過失者不在此限。</p>
                            <p>第十二條 資料安全<br/>本公司對於所保有消費者及其利害關係人之個人資料檔案，應採取適當之安全措施，防止個人資 料被竊取、竄改、毀損、滅失或洩露。本公司違反前項規定，致個人資料遭不法蒐集、處理、利</p>
                            <p><b>網路投保服務契約書</b><br/>用或其他侵害當事人權利者，應負損害賠償責任。但能證明其無故意或過失者，不在此限。</p>
                            <p>第十三條 資訊保密義務<br/>本公司因處理本契約及基於本契約所從事之保險電子交易，所取得之相關資料負有保密義務。除 經當事人同意或符合個人資料保護之相關法令規定外，本公司不得使用於與本契約無關之目的或 對第三人揭露。</p>
                            <p>第十四條 損害賠償責任<br/>因本契約雙方之故意或過失，就本契約傳送或接收電子訊息，有遲延、遺漏或錯誤之情事；或就 本契約所生義務之不履行或遲延履行，而致他方受有損害時，應負賠償責任。</p>
                            <p>第十五條 紀錄保存<br/>雙方應保存所有保險電子交易訊息（不含查詢類）紀錄，並應確保其真實性及完整性。本公司對 前項紀錄之保存，應盡善良管理人之注意義務。保存期限至少為保險契約有效期限屆滿或通知消 費者不同意承保後五年。</p>
                            <p>第十六條 電子訊息之效力<br/>雙方同意依本契約利用電子簽章或電子文件方式交換之電子訊息，其效力與書面簽署或書面文件 相同。</p>
                            <p>第十七條 消費者終止契約<br/>消費者得隨時通知本公司終止本契約。</p>
                            <p>第十八條 保險公司終止契約<br/>本公司欲終止本契約時，須於終止日三十日前以書面通知消費者。但消費者如有下列情事之一者， 本公司得隨時以書面通知消費者終止本契約：<br/>一、消費者未經本公司同意，擅自將本契約之權利或義務轉讓第三人。<br/>二、消費者受法院破產或重整宣告。<br/>三、消費者違反本契約第十二條第一項之規定。<br/>四、消費者違反本契約之其他約定，經催告改善或限期請求履行未果。</p>
                            <p>第十九條 通知處所<br/>消費者或本公司就本契約事項對他方為通知者，應向他方所留存本契約之最後地址或電子郵件信 箱為之。</p>
                            <p>第二十條 法令適用<br/>本契約準據法，依中華民國法律。</p>
                            <p><b>網路投保服務契約書</b></p>
                            <p>第二十一條 管轄法院<br/>因本契約涉訟者，雙方同意以消費者住所地地方法院為第一審管轄法院。消費者之住所在中華民 國境外時，以臺北地方法院為第一審管轄法院。但不得排除消費者保護法第四十七條及民事訴訟 法第四百三十六條之九小額訴訟管轄法院之適用。</p>
                            <p>第二十二條 契約修訂<br/>本契約如有未盡事宜，得經本公司及消費者協議補充或修正之。</p>
                        </div>

                    </div>

                </div>

            </div>
        </AppLayout>
    );
};

export default PageTermsAndConditions03;
